export const getQueryParam =(query)=>{
  const [,queries] = document?.location?.search?.split(query+'=')
  if(queries){
    const [query,] = queries?.split('&')
    return query
  }else{
    return null
  }
}
export const getTrackingHotmart =()=>{
  const utm_source = getQueryParam('utm_source')
  const utm_medium = getQueryParam('utm_medium')
  const utm_campaign = getQueryParam('utm_campaign')
  return [utm_source, utm_medium, utm_campaign].filter(Boolean).toString().replaceAll(',','|').slice(0,30)
}
export const getQueries =()=>{
  return document?.location?.search
}

// type Params = {
//   utm_source?: string
//   utm_medium?: string
//   utm_campaign?: string
//   cupom?: string
//   ln?: string
//   user_id?: string
// }

export function mapTrackingToHotmart(url){

}

export function setParams(url, params) {
  const urlObj = new URL(url)
  Object.keys(params).forEach((key) => {
    const value = params[key]
    if (value) {
      urlObj.searchParams.set(key, value)
    }
  })
  return urlObj.toString()
}